<template>
    <v-row>
        <empreendimentos></empreendimentos>
    </v-row>
</template>

<script>
import Empreendimentos from './Empreendimentos.vue'

export default {
    components: {
        Empreendimentos,
    },
    setup() {
        
    },
}
</script>