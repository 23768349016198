<template>
    <v-row no-gutters>
        <v-col class="col-12">
            <h3 v-if="this.empreendimentos.length == 0">Nenhuma obra em andamento encontrado.</h3>
        </v-col>
        <v-col class="col-12">
            <h2 class="" v-if="this.empreendimentos.length > 0">
                <span class="ml-1">Acompanhamento de Obra</span>
            </h2>
        </v-col>
        <div class="col-6" style="padding: 1em;"
            v-for="(empreendimento, index) in this.empreendimentos" :key="index">
            <v-card>
                <v-card-text>
                    <v-col class="col-md-12"
                        v-if="empreendimento.AcompanhamentoArquivos && empreendimento.AcompanhamentoArquivos.length > 0">
                        <v-carousel
                            style="height: 300px !important;" cycle hide-delimiter-background show-arrows="hover">
                            <div
                            v-for="(arquivo, index) in empreendimento.AcompanhamentoArquivos" :key="index">
                                <v-carousel-item
                                    v-if="arquivo.TipoArquivo === 0"
                                        style="max-width: 600px;"
                                        :src="arquivo.Url">
                                </v-carousel-item>
                            </div>
                        </v-carousel>
                    </v-col>
                    <v-row>
                        <v-col cols="12">
                            <h2>{{ empreendimento.Obra.Empreendimento }}</h2>
                            <p>{{ empreendimento.Obra.Obra }}</p>
                            <v-divider></v-divider>

                            <v-col class="col-12" v-if="empreendimento.AcompanhamentoArquivos && empreendimento.AcompanhamentoArquivos.some(function(x) { return x.TipoArquivo === 1; })">
                                <h3>
                                    <v-icon color="red">
                                            {{ icons.mdiFilePdfBox }}
                                        </v-icon>
                                    <span class="ml-1">Documentos da Obra</span>
                                </h3>
                                <v-sheet
                                    class="mx-auto"
                                    max-width="600"
                                    style="border-radius: 30px;">
                                    <v-slide-group
                                        multiple
                                        show-arrows>
                                        <v-slide-item
                                            v-for="(arquivo, index) in empreendimento.AcompanhamentoArquivos"
                                            v-if="arquivo.TipoArquivo === 1"
                                            :key="index"
                                        >
                                            <a :href="arquivo.Url" target="_blank" style="color: #FFF; text-decoration: none;">
                                                <v-btn
                                                    class="ma-2"
                                                    rounded
                                                    color="primary">
                                                        {{ arquivo.Nome === "" ? arquivo.FileNameKey : arquivo.Nome }}
                                                </v-btn>
                                            </a>
                                        </v-slide-item>
                                    </v-slide-group>
                                </v-sheet>
                            </v-col>

                            <v-divider></v-divider>
                            <v-row no-gutters class="mt-3">
                                <v-col class="col-md-4">
                                    <h3>
                                        <v-icon color="green">
                                            {{ icons.mdiStepForward }}
                                        </v-icon>
                                        <span class="ml-1">Etapa atual</span>
                                    </h3>
                                </v-col>
                                <v-col class="col-md-8 mt-1">
                                    <h3>{{ empreendimento.EtapaAtual }}</h3>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-3">
                                <v-col class="col-md-4">
                                    <h3>
                                        <v-icon color="green">
                                            {{ icons.mdiCheckDecagramOutline }}
                                        </v-icon>
                                        <span class="ml-1">Água Potável</span>
                                    </h3>
                                </v-col>
                                <v-col class="col-md-8 mt-1">
                                    <v-progress-linear
                                        :value="empreendimento.AguaPotavel"
                                        color="green light-green-darken-4"
                                        height="20"
                                        striped
                                        >
                                        <template>
                                            <strong style="color: rgb(255, 255, 255);"> {{ empreendimento.AguaPotavel.toFixed(2) }}%</strong>
                                        </template>
                                    </v-progress-linear>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-2">
                                <v-col class="col-md-4">
                                    <h3>
                                        <v-icon color="green">
                                            {{ icons.mdiCheckDecagramOutline }}
                                        </v-icon>
                                        <span class="ml-1">Águas Pluviais</span>
                                    </h3>
                                </v-col>
                                <v-col class="col-md-8 mt-1">
                                    <v-progress-linear
                                        :value="empreendimento.AguasPluviais"
                                        color="green light-green-darken-4"
                                        height="20"
                                        striped
                                        >
                                        <template>
                                            <strong style="color: rgb(255, 255, 255);"> {{ empreendimento.AguasPluviais.toFixed(2) }}%</strong>
                                        </template>
                                    </v-progress-linear>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-2">
                                <v-col class="col-md-4">
                                    <h3>
                                        <v-icon color="green">
                                            {{ icons.mdiCheckDecagramOutline }}
                                        </v-icon>
                                        <span class="ml-1">Asfalto</span>
                                    </h3>
                                </v-col>
                                <v-col class="col-md-8 mt-1">
                                    <v-progress-linear
                                        :value="empreendimento.Asfalto"
                                        color="green light-green-darken-4"
                                        height="20"
                                        striped
                                        >
                                        <template>
                                            <strong style="color: rgb(255, 255, 255);"> {{ empreendimento.Asfalto.toFixed(2) }}%</strong>
                                        </template>
                                    </v-progress-linear>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-2">
                                <v-col class="col-md-4">
                                    <h3>
                                        <v-icon color="green">
                                            {{ icons.mdiCheckDecagramOutline }}
                                        </v-icon>
                                        <span class="ml-1">Esgoto</span>
                                    </h3>
                                </v-col>
                                <v-col class="col-md-8 mt-1">
                                    <v-progress-linear
                                        :value="empreendimento.Esgoto"
                                        color="green light-green-darken-4"
                                        height="20"
                                        striped
                                        >
                                        <template>
                                            <strong style="color: rgb(255, 255, 255);"> {{ empreendimento.Esgoto.toFixed(2) }}%</strong>
                                        </template>
                                    </v-progress-linear>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-2">
                                <v-col class="col-md-4">
                                    <h3>
                                        <v-icon color="green">
                                            {{ icons.mdiCheckDecagramOutline }}
                                        </v-icon>
                                        <span class="ml-1">Meio Fio</span>
                                    </h3>
                                </v-col>
                                <v-col class="col-md-8 mt-1">
                                    <v-progress-linear
                                        :value="empreendimento.MeioFio"
                                        color="green light-green-darken-4"
                                        height="20"
                                        striped
                                        >
                                        <template>
                                            <strong style="color: rgb(255, 255, 255);"> {{ empreendimento.MeioFio.toFixed(2) }}%</strong>
                                        </template>
                                    </v-progress-linear>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-2">
                                <v-col class="col-md-4">
                                    <h3>
                                        <v-icon color="green">
                                            {{ icons.mdiCheckDecagramOutline }}
                                        </v-icon>
                                        <span class="ml-1">Obras Civis</span>
                                    </h3>
                                </v-col>
                                <v-col class="col-md-8 mt-1">
                                    <v-progress-linear
                                        :value="empreendimento.ObrasCivis"
                                        color="green light-green-darken-4"
                                        height="20"
                                        striped
                                        >
                                        <template>
                                            <strong style="color: rgb(255, 255, 255);"> {{ empreendimento.ObrasCivis.toFixed(2) }}%</strong>
                                        </template>
                                    </v-progress-linear>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-2">
                                <v-col class="col-md-1">
                                    <v-icon color="primary">
                                            {{ icons.mdiPercentOutline }}
                                    </v-icon>
                                </v-col>
                                <v-col class="col-md-11 mt-1">
                                    <v-progress-linear
                                        :value="empreendimento.ObrasCivis"
                                        color="primary"
                                        height="20"
                                        striped
                                        >
                                        <template>
                                            <strong style="color: rgb(255, 255, 255);"> {{ empreendimento.PercentualTotal.toFixed(2) }}%</strong>
                                        </template>
                                    </v-progress-linear>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
    </v-row>
  </template>

<script>
import { mdiOfficeBuildingMarkerOutline, mdiCheckDecagramOutline, mdiClockOutline, mdiFilePdfBox, mdiStepForward, mdiPercentOutline  } from '@mdi/js'
import Cookie from 'js-cookie'
import UsuarioStore from '@/store/Usuario'
import Strings from '@/common/strings'

export default {
    mounted: function(){
        this.$loading(true)
        UsuarioStore.obterAcompanhamento(Strings.imobiliariaId).then(result => {
            this.empreendimentos = result.data
            var dialogsShow = []

            this.empreendimentos.map((_, index) => {
                dialogsShow[index] = false
            })
        }).catch(error => {
            if(error.response.status == 401){
                Cookie.remove(Strings.cookie_token)
                Cookie.remove(Strings.cookie_username)
                this.$router.push('/pages/login')
            }
            console.log(error)
        }).finally(() => {
            this.$loading(false)
        })
    },
    data(){
      return{
        dialog: false,
        toastText: '',
        snackbar: false,
        empreendimentos: [],
        dialogs: Array
      }
    },
    methods: {
    },
    setup() {

      const momentF = Object
      
      return {
        icons:{
            mdiOfficeBuildingMarkerOutline,
            mdiCheckDecagramOutline,
            mdiClockOutline,
            mdiFilePdfBox,
            mdiStepForward,
            mdiPercentOutline 
        },
        momentF,
      }

    },
}
</script>

<style scoped>

</style>